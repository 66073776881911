






















































































































































































































































import { defineComponent, onMounted, ref, computed, reactive } from '@vue/composition-api'
import {
  endpoints,
  query,
  showError,
  toCamelCase,
  urlPath,
  groupMultiLevel,
  groupTwoLevel,
  mappingStock,
  convertToWidthCharacter,
  frameBusEvent,
  ORDER_DES
} from 'utils'
import { useExpansion } from 'hooks'
import { api, moment, framebus } from 'plugins'
import { Assign, PackingResult, ChildrenByItem, OrderDetail, Customer } from 'typings'
import {
  LoaderComponent,
  MultiLevelList,
  StepButton,
  OrderDialog,
  ItemAssignFromPacking,
  AddButton,
  AssignOrderFromPacking,
  MenuGeneralWhenAssigned,
  DateRange
} from 'components'
import { debounce, delay } from 'lodash'

interface SelectedLevel {
  rootIndex: number | null
  firstIndex: number | null
  secondIndex: number | null
}

interface OrderDetailExtend extends OrderDetail {
  customer: Customer
  customerName: string
  assignBoxes: number
  orderBoxes: number
  isSpecial: boolean
}

const AssignOrder = defineComponent({
  components: {
    LoaderComponent,
    MultiLevelList,
    StepButton,
    OrderDialog,
    ItemAssignFromPacking,
    AddButton,
    AssignOrderFromPacking,
    MenuGeneralWhenAssigned,
    DateRange
  },
  props: {},
  setup(props, { root }) {
    const { $router, $toast, $route, $store } = root
    const auctionDateModal = ref(false)
    const packedDateStartModal = ref(false)
    const packedDateEndModal = ref(false)

    const auctionDate = ref(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.aucDate || moment(new Date()).format('YYYY-MM-DD')
    )
    const packedDateStart = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[0]
        : $route.query.pkDateStart || moment(new Date()).format('YYYY-MM-DD')
    )
    const packedDateEnd = ref(
      $store.state.common.latestSelectedDates.packingDateRange
        ? $store.state.common.latestSelectedDates.packingDateRange[1]
        : $route.query.pkDateEnd || moment(new Date()).format('YYYY-MM-DD')
    )

    const dateRange = ref(['', ''])
    dateRange.value = [packedDateEnd.value, packedDateStart.value]
    const isShowActionOptions = ref(false)
    const actionOptionType = ref('GENERAL')
    const selectedLevel = reactive<SelectedLevel>({
      rootIndex: null,
      firstIndex: null,
      secondIndex: null
    })
    const packingResults = ref<Array<PackingResult>>([])
    const isSetAssign = ref(false)
    const ordersCanNotAssign = ref(0)
    const packingEmit = ref<any>({})
    const autoAssignAll = ref(false)
    const showMenuGeneral = ref(false)
    const orderProps = ref<any>({})
    const orderNotEnough = ref<number[]>([])
    const showDialog = ref(false)
    const assignments = ref<Array<Assign>>([])
    const orderDetails = ref<Array<OrderDetail>>([])
    const orderDetailsBy = ref<Array<OrderDetailExtend>>([])
    const orderDetailsFilter = ref<Array<OrderDetailExtend>>([])
    const orderDetailsByCustomer = ref<Array<any>>([])
    const orderDetailsByProduct = ref<Array<ChildrenByItem>>([])
    const nextAuctionDate = ref('')
    const treeviewLevel = ref(
      Number(
        $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
      ) || 4
    )

    const getSettingLevel = async (): Promise<void> => {
      treeviewLevel.value =
        Number(
          $store.state.common.settingMaster.find((e: any) => e.key === 'treeview_level')?.value
        ) || 4
    }

    const assignOrderByProductPanelState = computed(
      () => $store.state.assignOrder.assignOrderByProductPanelState
    )
    const assignOrderByCustomerPanelState = computed(
      () => $store.state.assignOrder.assignOrderByCustomerPanelState
    )
    const showBy = ref<string | any>($store.state.assignOrder.showBy || 'CUSTOMER')

    const loading = ref(false)
    const searchInfo = ref<string | any>($route.query.searchStr ? $route.query.searchStr : '')

    const showByItems = ref([
      { name: root.$t('customer') as string, key: 'CUSTOMER' },
      { name: root.$t('product') as string, key: 'PRODUCT' }
    ])

    const filterByItems = ref([
      { name: root.$t('assign_order.filter.show_all') as string, key: 'SHOW_ALL' },
      { name: root.$t('assign_order.filter.unassigned') as string, key: 'UNASSIGNED' },
      { name: root.$t('assign_order.filter.special') as string, key: 'SPECIAL' }
    ])
    const filterBy = ref<string | any>($store.state.assignOrder.filterBy)
    const filter = () => {
      // Update store
      $store.commit('setFilterByAssignOrder', filterBy.value)
      $store.commit('setShowByAssignOrder', showBy.value)
      const filterByStartsWith = orderDetailsBy.value.filter((orderDetail: OrderDetailExtend) => {
        const text = convertToWidthCharacter(searchInfo.value?.toLowerCase().trim(), 'full')
        const { item, variety, size, quality, customerName, customer } = orderDetail
        return (
          item.name?.toLowerCase().startsWith(text) ||
          variety.name?.toLowerCase().startsWith(text) ||
          size.name?.toLowerCase().startsWith(text) ||
          quality.name?.toLowerCase().startsWith(text) ||
          customer.name?.toLowerCase().startsWith(text) ||
          customerName?.toLowerCase().startsWith(text)
        )
      })
      const filterByIncludes = orderDetailsBy.value.filter((orderDetail: OrderDetailExtend) => {
        const text = convertToWidthCharacter(searchInfo.value?.toLowerCase().trim(), 'full')
        const { item, variety, size, quality, customerName, customer } = orderDetail
        return (
          !(
            item.name?.toLowerCase().startsWith(text) ||
            variety.name?.toLowerCase().startsWith(text) ||
            size.name?.toLowerCase().startsWith(text) ||
            quality.name?.toLowerCase().startsWith(text) ||
            customer.name?.toLowerCase().startsWith(text) ||
            customerName?.toLowerCase().startsWith(text)
          ) &&
          (item.name?.toLowerCase().includes(text) ||
            variety.name?.toLowerCase().includes(text) ||
            size.name?.toLowerCase().includes(text) ||
            quality.name?.toLowerCase().includes(text) ||
            customer.name?.toLowerCase().includes(text) ||
            customerName?.toLowerCase().includes(text))
        )
      })
      orderDetailsFilter.value = [...filterByStartsWith, ...filterByIncludes]
      if (filterBy.value === 'UNASSIGNED') {
        orderDetailsFilter.value = orderDetailsFilter.value.filter(
          (orderDetail: OrderDetailExtend) => !orderDetail.isAssigned
        )
      } else if (filterBy.value === 'SPECIAL') {
        orderDetailsFilter.value = orderDetailsFilter.value.filter(
          (orderDetail: OrderDetailExtend) => orderDetail.isSpecial
        )
      }
      orderDetailsByCustomer.value = groupTwoLevel(
        orderDetailsFilter.value,
        ['assignBoxes', 'packingBoxes', 'orderBoxes', 'orderedSpecialStems'],
        ['customerName']
      )
      orderDetailsByCustomer.value = mappingStock(
        orderDetailsByCustomer.value,
        {
          packingResults: packingResults.value,
          assignments: assignments.value
        },
        true,
        ORDER_DES
      )

      orderDetailsByProduct.value =
        treeviewLevel.value === 2
          ? groupTwoLevel(
              orderDetailsFilter.value,
              ['orderBoxes', 'assignBoxes', 'packingBoxes', 'orderedSpecialStems'],
              ['itemName']
            )
          : groupMultiLevel(
              orderDetailsFilter.value,
              ['orderBoxes', 'assignBoxes', 'packingBoxes', 'orderedSpecialStems'],
              ['itemName', 'varietyName', 'qualitySizeName', 'customerName']
            )
      orderDetailsByProduct.value = mappingStock(
        orderDetailsByProduct.value,
        {
          packingResults: packingResults.value,
          assignments: assignments.value
        },
        treeviewLevel.value === 2,
        ORDER_DES
      )
    }

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        nextAuctionDate.value = data.data.date
        auctionDate.value = nextAuctionDate.value as string
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getOrderDetailsByDate = async (
      pkDateStart: string,
      pkDateEnd: string,
      aucDate: string
    ): Promise<void> => {
      const queryStrPacking = query.buildQuery({
        start_date: pkDateStart,
        end_date: pkDateEnd
      })
      const queryStrOrder = query.buildQuery({
        auction_date: aucDate,
        order_by_customer: true
      })
      const data = await Promise.all([
        api.get(`${endpoints.ORDER_DETAILS}?${queryStrOrder}`),
        api.get(`${endpoints.PACKING_RESULTS}get_packing_result_by_range?${queryStrPacking}`),
        api.get(
          `${endpoints.ASSIGNMENTS}?auction_date=${aucDate}&is_filter_has_order_detail=${true}`
        )
      ])
      const [{ data: orderDetailsData }, { data: packingResultsData }, { data: assignmentsData }] =
        data
      orderDetails.value = toCamelCase(orderDetailsData).map((orderDetail: any) => ({
        ...orderDetail,
        orderedSpecialStems: orderDetail.boxes ? 0 : orderDetail.stems
      }))
      assignments.value = toCamelCase(assignmentsData)

      packingResults.value = toCamelCase(packingResultsData)

      orderDetailsBy.value = orderDetails.value.map((e: any) => {
        return {
          ...e,
          customer: e.order.customer,
          customerName: `[${e.order.customer.code}] ${e.order.customer.shortName}`,
          orderBoxes: e.boxes || 0
        }
      })

      filter()
    }

    const setDate = (start: string, end: string) => {
      packedDateStart.value = start
      packedDateEnd.value = end
    }

    const getDataByDate = async (
      aucDate: string,
      pkDateStart: string,
      pkDateEnd: string
    ): Promise<void> => {
      loading.value = true
      try {
        await getOrderDetailsByDate(pkDateStart, pkDateEnd, aucDate)
      } catch (e) {
        console.log(e)
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        auctionDateModal.value = false
        packedDateStartModal.value = false
        packedDateEndModal.value = false
        loading.value = false
      }
    }

    const openBottomMenu = (data: PackingResult): void => {
      // Select order
      isSetAssign.value = false
      actionOptionType.value = 'ON_ORDER'
      isShowActionOptions.value = true
      orderProps.value = data
    }

    const goToDate = () => {
      $router
        .replace({
          name: urlPath.ASSIGN_ORDER.name,
          query: {
            aucDate: auctionDate.value,
            pkDateStart: packedDateStart.value,
            pkDateEnd: packedDateEnd.value,
            called: 'true'
          }
        })
        .catch((err) => {
          // Ignore the vuex err regarding navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            console.log(err)
          }
        })
      const latestSelectedDates = {
        auctionDate: auctionDate.value.toString(),
        packingDateRange: [packedDateStart.value, packedDateEnd.value]
      }
      // eslint-disable-next-line no-restricted-globals
      // parent.postMessage(latestSelectedDates, '*')
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
      getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)
    }

    const updatePanelState = (mutation: string, panelState: Record<string, unknown>) => {
      $store.commit(mutation, panelState)
    }
    const openActionSheet = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      actionOptionType.value = 'ON_LEVEL'
      isShowActionOptions.value = true
      selectedLevel.rootIndex = rootIndex
      selectedLevel.firstIndex = firstIndex
      selectedLevel.secondIndex = secondIndex
    }

    const updatePackedDateStart = () => {
      if (packedDateStart.value > packedDateEnd.value) {
        packedDateEnd.value = packedDateStart.value
      }
    }

    const { getExpandPanelState, getCollapsePanelState } = useExpansion()

    const currentDataState = computed(() => {
      let data: Array<any> = []
      let action = ''
      let keys: Array<string> = []
      let state = {}

      switch (showBy.value) {
        case 'PRODUCT':
          data = orderDetailsByProduct.value
          action = 'setAssignOrderByProductPanelState'
          keys = ['itemName', 'varietyName', 'qualitySizeName', 'customerName']
          state = assignOrderByProductPanelState.value
          break
        case 'CUSTOMER':
          data = orderDetailsByCustomer.value
          action = 'setAssignOrderByCustomerPanelState'
          keys = ['customerName']
          state = assignOrderByCustomerPanelState.value
          break
        default:
      }
      return { data, action, keys, state }
    })

    const expandAll = () => {
      const { data, action, keys, state } = currentDataState.value
      const argument = {
        data,
        keys,
        state,
        selected: selectedLevel
      }

      updatePanelState(action, getExpandPanelState(argument))
    }

    const collapseAll = () => {
      const { data, action, keys, state } = currentDataState.value
      const argument = {
        data,
        keys,
        state,
        selected: selectedLevel
      }

      updatePanelState(action, getCollapsePanelState(argument))
    }

    const handleSearchInput = debounce((value: string) => {
      if (value !== searchInfo.value) {
        searchInfo.value = value

        delay(
          () => {
            $router
              .replace({
                query: {
                  aucDate: auctionDate.value,
                  pkDateStart: packedDateStart.value,
                  pkDateEnd: packedDateEnd.value,
                  searchStr: searchInfo.value,
                  called: 'true'
                }
              })
              .catch((e) => {
                console.log(e)
              })
          },
          100,
          true
        )
        filter()
      }
    }, 500)

    const reload = async (enableScrollToPreviousPosition: boolean) => {
      // get position before reload
      const positionY = window.scrollY

      await getSettingLevel()
      await getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)

      // scroll to previous position
      if (enableScrollToPreviousPosition) {
        window.scrollTo(0, positionY)
      }
    }

    const markAsAssigned = async () => {
      try {
        const { data } = await api.put(
          `${endpoints.ORDER_DETAILS}update_is_assigned/${orderNotEnough.value[0]}`
        )
        const newOrderDetail = toCamelCase(data)
        const ids = orderDetails.value.map((orderDetail: OrderDetail) => {
          return orderDetail.id
        })
        const index = ids.indexOf(newOrderDetail.id)
        orderDetails.value[index] = newOrderDetail
        orderDetailsBy.value = orderDetails.value.map((e: any) => {
          return {
            ...e,
            customer: e.order.customer,
            customerName: `[${e.order.customer.code}] ${e.order.customer.name}`,
            orderBoxes: e.boxes || 0
          }
        })
        filter()
        $toast.success(root.$t('common.msg.update_success'))
      } catch (e) {
        showError(e, $toast, root.$t('assign_order.update_failed') as string)
      }
    }

    const autoAssign = async (orderDetailId: number | null = null) => {
      autoAssignAll.value = orderDetailId === null
      // showMenuGeneral.value = orderDetailId === null
      try {
        const param = {
          order_detail_ids: orderDetailId
            ? [orderDetailId]
            : orderDetailsFilter.value.map((orderDetail: OrderDetailExtend) => orderDetail.id),
          auction_date: auctionDate.value,
          start_date: packedDateStart.value,
          end_date: packedDateEnd.value
        }
        const { data } = await api.post(`${endpoints.ASSIGNMENTS}auto_assign`, param)
        const autoAssignData = toCamelCase(data)
        orderNotEnough.value = autoAssignData.orderNotEnough
        if (autoAssignData.assignInserted === 0) {
          $toast.info(root.$t('assign_order.auto_assign_successful', { assigned: 0 }))
        }
        ordersCanNotAssign.value = autoAssignData.order - autoAssignData.assignedOrder
        if (autoAssignData.assignedOrder > 0) {
          $toast.success(
            root.$t('assign_order.auto_assign_successful', {
              assigned: autoAssignData.assignedOrder
            })
          )
        }
        if (
          (orderNotEnough.value.length > 0 ||
            autoAssignData.assignedOrder !== autoAssignData.order) &&
          autoAssignData.assignInserted > 0
        ) {
          showMenuGeneral.value = true
        }
        reload(true)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const updatePackingEmit = (data: any) => {
      packingEmit.value[orderProps.value.id] = data
    }

    const onYes = async () => {
      if (!isSetAssign.value) {
        if (autoAssignAll.value) {
          filterBy.value = 'UNASSIGNED'
          filter()
        } else if (orderNotEnough.value.length > 0) {
          await markAsAssigned()
        }
      } else {
        try {
          const orderId =
            orderNotEnough.value[0] === undefined ? orderProps.value.id : orderNotEnough.value[0]
          await api.put(`${endpoints.ORDER_DETAILS}update_is_assigned/${orderId}`)
          $toast.success(root.$t('common.msg.update_success'))
        } catch (e) {
          showError(e, $toast, root.$t('assign_order.update_failed') as string)
        }
      }
      showMenuGeneral.value = false
      reload(true)
    }

    const goToOrderDetail = () => {
      $router
        .push({
          name: urlPath.ORDER_DETAIL_FORM.name,
          params: {
            orderId: orderProps.value.order.id.toString(),
            detailId: orderProps.value.id.toString(),
            auctionDate: orderProps.value.order.auctionDate,
            isDuplicated: '0'
          },
          query: { called: 'true' }
        })
        .catch((e) => {
          console.log(e)
        })
    }

    const onClose = (autoClose: boolean, data: boolean) => {
      showDialog.value = false
      autoAssignAll.value = false
      isSetAssign.value = true
      showMenuGeneral.value = autoClose && data
    }

    const openActionOptions = () => {
      isSetAssign.value = false
      isShowActionOptions.value = true
      actionOptionType.value = 'GENERAL'
    }

    const inputAssignment = () => {
      $router.push({
        name: urlPath.ASSIGN_FORM.name,
        params: { assignId: 'create' },
        query: {
          auction_date: auctionDate.value,
          order_detail_info: JSON.stringify(orderProps.value),
          called: 'true'
        }
      })
    }

    onMounted(async () => {
      await getSettingLevel()
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        )
      )
        await getNextAuctionDate()
      await getDataByDate(auctionDate.value, packedDateStart.value, packedDateEnd.value)
    })

    return {
      showDialog,
      dateRange,
      auctionDate,
      packedDateStart,
      packedDateEnd,
      auctionDateModal,
      packedDateStartModal,
      packedDateEndModal,
      showBy,
      showByItems,
      filterBy,
      filterByItems,
      goToDate,
      packingResults,
      loading,
      orderDetails,
      orderDetailsByCustomer,
      orderDetailsByProduct,
      assignOrderByProductPanelState,
      assignOrderByCustomerPanelState,
      updatePanelState,
      openActionSheet,
      updatePackedDateStart,
      expandAll,
      collapseAll,
      isShowActionOptions,
      handleSearchInput,
      searchInfo,
      openBottomMenu,
      reload,
      filter,
      orderProps,
      assignments,
      actionOptionType,
      autoAssign,
      updatePackingEmit,
      packingEmit,
      autoAssignAll,
      showMenuGeneral,
      onYes,
      inputAssignment,
      onClose,
      ordersCanNotAssign,
      openActionOptions,
      setDate,
      treeviewLevel,
      goToOrderDetail
    }
  }
})

export default AssignOrder
